//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import UserBalanceBalances from '~/views/UserBalance/UserBalanceBalances/UserBalanceBalances.vue';
import UserBalanceTransactions from '~/views/UserBalance/UserBalanceTransactions/TransactionsHistory.vue';
import UserBalanceAddresses from '~/views/UserBalance/UserBalanceAddresses/UserBalanceAddresses.vue';
import UserBalanceLimits from '~/views/UserBalance/UserBalanceLimits/UserBalanceLimits.vue';

export default {
  components: {
    UserBalanceBalances,
    UserBalanceTransactions,
    UserBalanceAddresses,
    UserBalanceLimits,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.getUserTransactionsHistory(),
      this.loadBalanceLimits(),
      this.loadAddresses(),
      this.loadCryptoCurrencies(),
      this.loadCryptoTokens(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('userBalanceTransactions', {
      getUserTransactionsHistory: 'getTransactionsHistory',
    }),
    ...mapActions('userBalanceAddresses', {
      loadAddresses: 'loadData',
    }),
    ...mapActions('userBalanceLimits', {
      loadBalanceLimits: 'loadData',
    }),
    ...mapActions('paymentsAndCurrenciesPayments', {
      loadCryptoCurrencies: 'loadCryptoCurrencies',
      loadCryptoTokens: 'loadCryptoTokens',
    }),
  },
};
