import { render, staticRenderFns } from "./TransactionsHistory.vue?vue&type=template&id=3afb3671&scoped=true&"
import script from "./TransactionsHistory.vue?vue&type=script&lang=js&"
export * from "./TransactionsHistory.vue?vue&type=script&lang=js&"
import style0 from "./TransactionsHistory.vue?vue&type=style&index=0&id=3afb3671&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3afb3671",
  null
  
)

export default component.exports