export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'slot',
      name: 'currency',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Balance',
      sort: 'availableFunds',
    },
    cell: {
      type: 'slot',
      name: 'availableFunds',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'All hold',
      sort: 'frozenFunds',
    },
    cell: {
      type: 'slot',
      name: 'frozenFunds',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Deposit count',
      sort: 'depositCount',
    },
    cell: {
      type: 'slot',
      name: 'depositCount',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Withdrawal count',
      sort: 'withdrawalCount',
    },
    cell: {
      type: 'slot',
      name: 'withdrawalCount',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Total deposit',
      sort: 'depositTotal',
    },
    cell: {
      type: 'slot',
      name: 'depositTotal',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Deposit fee',
    },
    cell: {
      type: 'slot',
      name: 'depositFee',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Total withdrawal',
      sort: 'withdrawalTotal',
    },
    cell: {
      type: 'slot',
      name: 'withdrawalTotal',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Withdrawal fee',
    },
    cell: {
      type: 'slot',
      name: 'withdrawalFee',
    },
  },
];
