//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './UserBalanceBalancesFilters.vue';
import GetReport from '~/components/GetReport.vue';
import UserBalanceBalancesChart from './UserBalanceBalancesChart.vue';
import AddBalanceModal from './UserBalanceBalancesAddBalanceModal.vue';

import tableConfig from './tableConfig';
import constant from '~/const';

export default {
  components: {
    Filters,
    GetReport,
    UserBalanceBalancesChart,
    AddBalanceModal,
  },

  data() {
    return {
      tableConfig,
      constant,
      selection: [],
      searchString: '',
      searchCurrencies: [],
      loader: false,
      addBalanceState: false,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('currencies', ['getCurrencyById']),

    ...mapState('currencies', ['currencies']),
    ...mapState('userBalanceBalances', ['page', 'pages', 'data', 'additionalData', 'sort', 'filters']),
    ...mapGetters('userBalanceBalances', ['requestData']),

    filteredData() {
      const { searchString, data } = this;
      return data
        .filter((e) => {
          const text = e.currencyTitle.toLowerCase();
          const query = searchString.toLowerCase();
          return text.includes(query);
        })
        .filter((e) => {
          return !this.searchCurrencies.length || this.searchCurrencies.includes(e.currencyTitle);
        });
    },

    routerId() {
      return this.$route.params.id;
    },

    currenciesFiltered() {
      return this.currencies.filter((e) => {
        return !this.filters.currencyTypeList?.length || this.filters.currencyTypeList.includes(e.currencyType);
      });
    },
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setSuccessNotification', 'setGeneralProgress']),
    ...mapActions('userBalanceBalances', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    getCurrency(id) {
      const currency = this.getCurrencyById(id);
      if (currency) {
        return currency.title;
      }
      return '';
    },

    getAvailableBalance(row) {
      const { balances } = row;
      if (balances && balances.length) {
        let sum = 0;
        balances.forEach((e) => {
          sum += e.availableFunds;
        });
        return sum;
      }
      return 0;
    },

    getCumulativeAvailableBalance(row) {
      const { balances } = row;
      if (balances && balances.length) {
        let sum = 0;
        balances.forEach((e) => {
          sum += e.cumulativeAvailableFunds;
        });
        return sum.toFixed(2);
      }
      return 0;
    },

    getFrozenBalance(row) {
      const { balances } = row;
      if (balances && balances.length) {
        let sum = 0;
        balances.forEach((e) => {
          sum += e.frozenFunds;
        });
        return sum;
      }
      return 0;
    },

    addBalanceToUser() {
      this.addBalanceState = !this.addBalanceState;
    },

    closeModalAddBalance() {
      this.addBalanceState = false;
    },
  },
};
