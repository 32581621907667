var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transactions-history"},[_c('div',{staticClass:"transactions-history__filters"},[_c('TransactionsHistoryFilters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('GetReport',{attrs:{"type":_vm.reportOperations,"command":_vm.requestData}})],1),_c('div',{staticClass:"transactions-history__table"},[_c('Table',{attrs:{"data":_vm.transactionsHistory,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px","show-extra":"","to-set":function (e) { return ((e.id) + "/" + (e.operationType) + "/" + (e.side)); }},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"operation":row}})]}},{key:"id",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"transactions-history__id"},[_c('div',[_vm._v(_vm._s(row.id))]),_c('div',{staticClass:"text--secondary"},[(row.internal)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Internal'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('External'))+" ")])])])]}},{key:"dateCreated",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.dateCreated)))])])]}},{key:"ip",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ipAddress)+" ")]}},{key:"user",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.userId,"username":row.userName}})]}},{key:"side",fn:function(ref){
var row = ref.row;
return [_c('span',{class:{
            'success--text': row.side === _vm.constant.operations.OPERATION_SIDE.IN,
            'error--text': row.side === _vm.constant.operations.OPERATION_SIDE.OUT,
          }},[_vm._v(" "+_vm._s(_vm.$utils.getCaption(_vm.constant.operations.OPERATION_SIDE_LIST, row.side))+" ")])]}},{key:"type",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getCaption(_vm.constant.operations.OPERATION_TYPE_LIST, row.operationType))+" ")]}},{key:"statusHeader",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Status'))+" "),_c('v-icon',{staticClass:"ml-1",on:{"click":function($event){_vm.showTransactionScheme = true}}},[_vm._v(" mdi-information-outline ")])]},proxy:true},{key:"status",fn:function(ref){
          var row = ref.row;
return [(_vm.isStatusCanBeChanged(row))?_c('span',{staticClass:"text-decoration-underline",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.openStatusModal(row.id, row.operationType)}}},[_vm._v(" "+_vm._s(_vm.$utils.getCaption( _vm.constant.operations.TRANSACTION_STATUS_LIST, row.transactionStatus ))+" ")]):_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$utils.getCaption( _vm.constant.operations.TRANSACTION_STATUS_LIST, row.transactionStatus ))+" ")])]}},{key:"amount",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.sciToDec(row.amount))+" "+_vm._s(row.currencyTitle)+" ")]}},{key:"method",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(row.transactionMethodType)+" ")]}},{key:"fee",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(row.fee)+" ")]}},{key:"mfa",fn:function(ref){
          var row = ref.row;
return [(row.nfaConfirmedList.length)?[(row.nfaUnconfirmedList.length)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"error--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(row.nfaConfirmedList.length + row.nfaUnconfirmedList.length)+" ")])]}}],null,true)},[_c('div',_vm._l((row.nfaUnconfirmedList.length),function(nfa){return _c('div',{key:nfa},[_vm._v(" "+_vm._s(_vm.$utils.getCaption(_vm.constant.users.NFA_SERVICE_TYPE_LIST, nfa))+" ")])}),0)])]:[_c('div',[_vm._v(_vm._s(row.nfaConfirmedList.length + row.nfaUnconfirmedList.length))])]]:[_vm._v(" – ")]]}},{key:"api",fn:function(ref){
          var row = ref.row;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(row.sourceType === _vm.constant.api.SOURCE_TYPE.PUBLIC)?_c('span',[_vm._v("yes")]):_c('span',[_vm._v("no")])])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$utils.getCaption(_vm.constant.api.SOURCE_TYPE_LIST, row.sourceType))+" ")])]}},{key:"requisites",fn:function(ref){
          var row = ref.row;
return [_c('RequisitesIcon',{attrs:{"status":row.requisitesStatus}})]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('Pagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1),_c('StatusModal',{attrs:{"data":_vm.statusModalData},on:{"processing":_vm.processing,"confirm":_vm.confirm,"complete":_vm.complete,"cancel":_vm.cancel,"decline":_vm.decline,"needContact":_vm.needContact,"check":_vm.check,"cancelCompleted":_vm.cancelCompleted},model:{value:(_vm.statusModal.isOpen),callback:function ($$v) {_vm.$set(_vm.statusModal, "isOpen", $$v)},expression:"statusModal.isOpen"}}),_c('TransactionScheme',{model:{value:(_vm.showTransactionScheme),callback:function ($$v) {_vm.showTransactionScheme=$$v},expression:"showTransactionScheme"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }