//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';
import SettingFeesAndLimits from '~/components/modals/SettingFeesAndLimits.vue';
import constant from '~/const';

export default {
  components: { SettingFeesAndLimits },

  data() {
    return {
      settingFeesAndLimits: {
        modal: false,
      },
    };
  },

  computed: {
    ...mapState('currencies', ['currencies']),
    ...mapState('user', ['user']),
    ...mapState('userBalanceLimits', ['data']),
    ...mapGetters('currencies', ['getCurrencyById']),

    config() {
      return {
        range: [
          { title: this.$t('Daily'), value: constant.transactions.LIMIT_PERIOD.DAY },
          { title: this.$t('Monthly'), value: constant.transactions.LIMIT_PERIOD.MONTH },
        ],
        types: constant.transactions.LIMIT_TYPE_LIST.map((e) => ({
          title: this.$t(`tableHeader.${e.caption}`),
          value: `limit${e.id}`,
        })),
      };
    },
  },

  created() {
    this.setDefaultCurrencyEquivalent();
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('users', {
      updateUserTransactionLimitAction: 'updateUserTransactionLimit',
      deleteUserTransactionLimitAction: 'deleteUserTransactionLimit',
    }),
    ...mapActions('userBalanceLimits', {
      loadDataAction: 'loadData',
      setCurrencyIdAction: 'setCurrencyId',
    }),

    setDefaultCurrencyEquivalent() {
      const { DEFAULT_PRICE_EQUIVALENT_CURRENCY_TITLE } = constant.currencies;
      const currency = this.currencies.find((e) => (
        e.title === DEFAULT_PRICE_EQUIVALENT_CURRENCY_TITLE
      ));
      if (currency) this.setCurrencyIdAction(currency.id);
    },

    getValue(range, type) {
      const { data } = this;
      const value = data.find((e) => e.limitPeriod === range);
      if (value && Number.isFinite(value[type])) {
        const currency = this.getCurrencyById(value.currencyId);
        return `${value[type]} ${currency.title}`;
      }
      return '–';
    },

    edit() {
      this.settingFeesAndLimits.modal = true;
    },

    async applySetFeesAndLimits({ state, currencyId }) {
      const { id } = this.user;
      const { types } = this.config;
      const payload = state.map((e) => ({
        ...e,
        userId: id,
        currencyId,
      }));
      this.setGeneralProgress(true);
      try {
        await Promise.all(
          payload.map((e) => {
            if (types.some((t) => e[t.value])) {
              return this.updateUserTransactionLimitAction(e);
            }
            if (e.id) {
              return this.deleteUserTransactionLimitAction({
                limitId: e.id,
              });
            }
            return null;
          }),
        );
        await this.loadDataAction();
        this.settingFeesAndLimits.modal = false;
        this.setSuccessNotification('Limits changed successfully');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },
  },
};
