//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import Filters from '~/components/filters/Index.vue';
import TextField from '~/components/ui/TextField.vue';
import Select from '~/components/ui/Select.vue';
import DateRange from '~/components/ui/DateRange.vue';

import constant from '~/const';

export default {

  components: {
    Filters,
    TextField,
    Select,
    DateRange,
  },
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        searchString: '',
        dateCreatedFrom: '',
        dateCreatedTo: '',
        operationTypeList: [],
        side: null,
        currencyIdList: [],
        cryptoCurrencyIdList: [],
        cryptoTokenIdList: [],
        transactionStatusList: [],
        transactionMethodIdList: [],
      },
      constant,
    };
  },

  computed: {
    ...mapState('userBalanceTransactions', ['filterLists']),
    ...mapState('currencies', ['currencies']),
    ...mapState('paymentsAndCurrenciesPayments', [
      'cryptoCurrencies',
      'cryptoTokens',
    ]),

    cryptoTokensFiltered() {
      const { cryptoTokens } = this;
      const { cryptoCurrencyIdList } = this.filters;
      return cryptoTokens.filter((e) => cryptoCurrencyIdList.includes(e.cryptoCurrencyId));
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('userBalanceTransactions', ['setFilters']),
  },
};
