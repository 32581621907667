//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import * as d3 from 'd3';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
    data: { type: Array, default: () => ([]) },
    filteredData: { type: Array, default: () => ([]) },
    cumulativeCurrencyTitle: { type: String, default: '' },
  },

  computed: {
    ...mapGetters('currencies', ['toCurrencyFormat']),
    totalBalanceList() {
      const { data } = this;
      return data.map((currency) => {
        const cumulativeAvailableFunds = currency.balances.reduce(
          (a, c) => (a + c.cumulativeAvailableFunds),
          0,
        );
        return {
          currencyId: currency.currencyId,
          currencyTitle: currency.currencyTitle,
          cumulativeAvailableFunds,
        };
      });
    },

    totalBalanceFiltered() {
      const { filteredData } = this;
      return filteredData
        .map((currency) => {
          const cumulativeAvailableFunds = currency.balances.reduce(
            (a, c) => (a + c.cumulativeAvailableFunds),
            0,
          );
          return {
            currencyId: currency.currencyId,
            currencyTitle: currency.currencyTitle,
            cumulativeAvailableFunds,
          };
        })
        .reduce((a, c) => (a + c.cumulativeAvailableFunds), 0);
    },

    totalBalance() {
      const { totalBalanceList } = this;
      return totalBalanceList.reduce((a, c) => (a + c.cumulativeAvailableFunds), 0);
    },

    histogramData() {
      const histogramList = constant.balance.HISTOGRAM_CURRENCY_LIST.map((e) => ({
        ...e,
        cumulativeAvailableFunds: 0,
      }));
      const { totalBalanceList, totalBalance } = this;
      const defaultHistogramItem = histogramList.find((e) => e.default);

      totalBalanceList.forEach((e) => {
        const histogramItem = histogramList.find((h) => h.title === e.currencyTitle);
        if (histogramItem) {
          histogramItem.cumulativeAvailableFunds = e.cumulativeAvailableFunds;
        } else {
          defaultHistogramItem.cumulativeAvailableFunds += e.cumulativeAvailableFunds;
        }
      });
      return histogramList.map((e) => ({
        ...e,
        cumulativeAvailableFundsPercent: totalBalance
          ? e.cumulativeAvailableFunds / totalBalance
          : 0,
      }));
    },
  },

  watch: {
    async isLoading(val) {
      const { totalBalance } = this;
      await this.$nextTick();
      if (!val && totalBalance) this.drawChart();
    },
  },

  methods: {
    drawChart() {
      const data = this.histogramData.map((e) => e.cumulativeAvailableFunds);

      const canvas = this.$refs.chart;
      const context = canvas.getContext('2d');

      const { width, height } = canvas;
      const radius = Math.min(width, height) / 2;

      const colors = this.histogramData.map((e) => e.color);

      const arc = d3.arc()
        .outerRadius(radius)
        .innerRadius(radius - 20)
        .context(context);

      const pie = d3.pie();

      const arcs = pie(data);

      context.translate(width / 2, height / 2);

      arcs.forEach((d, i) => {
        context.beginPath();
        arc(d);
        context.fillStyle = colors[i];
        context.fill();
      });
    },
  },
};
