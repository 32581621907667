//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from 'vuex';
import constant from '~/const';
import RequisitesIcon from '~/components/RequisitesIcon.vue';
import WarningAlert from '~/components/users/WarningAlert.vue';

export default {

  components: {
    RequisitesIcon,
    WarningAlert,
  },
  props: {
    data: { type: Object, default: () => ({}) },
    history: { type: Array, default: () => [] },
    user: { type: Object, default: () => ({}) },
    transactionMethod: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      constant,
      markingRequisites: {
        modal: false,
      },
      moreAboutAddress: {
        modal: false,
      },
    };
  },

  computed: {
    ...mapGetters('login', ['getAdminById']),
    ...mapGetters('currencies', [
      'getUrlTx',
      'getUrlAddress',
      'getTokenUrlTx',
      'getTokenUrlAddress',
      'getCurrencyById',
    ]),
    ...mapState('paymentsAndCurrenciesPayments', [
      'cryptoCurrencies',
      'cryptoTokens',
    ]),

    hasRequisites() {
      const { requisites } = this.data.transaction;
      return !!requisites;
    },

    requisitesStatus() {
      const { requisitesStatus } = this.data.transaction.requisites;
      return requisitesStatus;
    },

    requisites() {
      const { requisites } = this.data.transaction.requisites;
      return requisites;
    },

    requisitesId() {
      const { id } = this.data.transaction.requisites;
      return id;
    },

    txId() {
      try {
        const { txId } = this.data.transaction.cryptoTransaction;
        return txId;
      } catch (error) {
        return null;
      }
    },

    urlTx() {
      const { txId } = this;
      const cryptoCurrencyId = this.transactionMethod?.cryptoCurrencyId;
      const cryptoTokenId = this.transactionMethod?.cryptoTokenId;
      if (txId && cryptoTokenId) {
        return this.getTokenUrlTx(txId, cryptoTokenId);
      }
      if (txId && cryptoCurrencyId) {
        return this.getUrlTx(txId, cryptoCurrencyId);
      }
      return null;
    },

    address() {
      try {
        const { address } = this.data.transaction.cryptoTransaction;
        return address;
      } catch (error) {
        return null;
      }
    },

    urlAddress() {
      const { address } = this;
      const cryptoCurrencyId = this.transactionMethod?.cryptoCurrencyId;
      const cryptoTokenId = this.transactionMethod?.cryptoTokenId;
      if (address && cryptoTokenId) {
        return this.getTokenUrlAddress(address, cryptoTokenId);
      }
      if (address && cryptoCurrencyId) {
        return this.getUrlAddress(address, cryptoCurrencyId);
      }
      return null;
    },

    userWarningList() {
      const { userWarningList } = this.user;
      return userWarningList;
    },

    cryptoCurrency() {
      const { transactionMethod } = this;
      if (!transactionMethod) return null;

      const { cryptoCurrencyId } = transactionMethod;
      return this.getCryptoCurrencyTitleById(cryptoCurrencyId);
    },

    cryptoToken() {
      const { transactionMethod } = this;
      if (!transactionMethod) return null;

      const { cryptoTokenId } = transactionMethod;
      return this.getCryptoTokenTitleById(cryptoTokenId);
    },

    balanceBefore() {
      const { history } = this;
      const getFirstByDate = history.reduce((a, b) => (a.dateCreated < b.dateCreated ? a : b), {});
      return getFirstByDate.availableFunds || '–';
    },

    balanceAfter() {
      const { history } = this;
      const getLastByDate = history.reduce((a, b) => (a.dateCreated > b.dateCreated ? a : b), {});
      return getLastByDate.availableFunds || '–';
    },
  },

  methods: {
    ...mapActions('common', ['setGeneralProgress']),
    ...mapActions('transactionsHistory', {
      updateTransactionRequisitesAction: 'updateTransactionRequisites',
    }),

    confirmMarkRequisites() {
      this.markingRequisites.modal = true;
    },

    async markRequisites(value) {
      const { requisitesId } = this;

      this.markingRequisites.modal = false;
      this.setGeneralProgress(true);
      await this.updateTransactionRequisitesAction({
        id: requisitesId,
        requisitesStatus: value,
      });
      this.setGeneralProgress(false);
      this.$emit('reload');
    },

    getCryptoCurrencyTitleById(id) {
      const { cryptoCurrencies } = this;
      const currency = cryptoCurrencies.find((e) => e.id === id);
      if (currency) {
        return currency.title;
      }
      return null;
    },

    getCryptoTokenTitleById(id) {
      const { cryptoTokens } = this;
      const currency = cryptoTokens.find((e) => e.id === id);
      if (currency) {
        return currency.title;
      }
      return null;
    },
  },
};
